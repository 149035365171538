import React, {useEffect, useState} from 'react';
import {Container, createTheme, ThemeProvider} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {QueryClient, QueryClientProvider, useQuery} from 'react-query';
import FilterForm from "../components/FilterForm";
import HeroSection from "../components/HeroSection";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {setFilters, setPageIgDisplay, setPageNormalDisplay, setPageSize} from '../store/filterSlice'; // Import setFilters from the correct slice
import CarPostList from "../components/CarPostList";
import {API_BASE_URL} from "../store/carDetailsSlice";

export interface CarItem {
    id: string;
    brand: string;
    model: string;
    generation: string;
    trim: string;
    year: number;
    km: number;
    fuelTypeId: number;
    price: string;
    priceCurrency: string;
    phoneNumbers: string;
    transmission: string;
    isSold: boolean;
    username: string;
    caption: string;
    postShortcode: string;
    mediaId: string;
    postedTime: string;
    numberOfPictures: number | null;
}

// Create query client
const queryClient = new QueryClient();

export const golfMapping = {
    xi: '11',
    x: '10',
    ix: '9',
    viii: '8',
    vii: '7',
    vi: '6',
    v: '5',
    iv: '4',
    iii: '3',
    ii: '2',
    i: '1',
};

export const theme = createTheme({
    typography: {
        fontFamily: 'Inter, sans-serif',
    },
});

export const padding = '3px';
export const backgroundColor = "#eee";
export const TRIM_LETTERS_TO_SLICE = 25;

export function getPriceLabel(car: CarItem) {
    if (car.price && Math.round(parseInt(car.price)) > 300000) {
        // It is probably LEK.
        return formatNumberWithDots(Math.round(parseInt(car.price))) + " LEK";
    }

    return !car.price ? "€ --" : car.priceCurrency + " " + formatNumberWithDots(Math.round(parseInt(car.price)));
}


function tryGolfCarModel(car: CarItem, carModel: string) {
    if (car.model.toLowerCase() !== "golf") {
        return carModel;
    }

    for (const key in golfMapping) {
        if (
            golfMapping.hasOwnProperty(key) &&
            car.generation?.toLowerCase().includes(`golf ${key}`)
        ) {
            return `Golf ${golfMapping[key as keyof typeof golfMapping]}
            ${car.generation?.toLowerCase().includes("plus") ? " Plus" : ""}`;
        }
    }

    return carModel;
}

function tryMercedesBenzCarModel(car: CarItem, carModel: string) {
    if (car.brand.toLowerCase() !== "mercedes-benz") {
        return carModel;
    }

    return carModel.length < 4 ? carModel.toUpperCase() : carModel;
}

function tryLongSuffix(car: CarItem, text: string) {
    if (car?.generation?.includes("Long")
        && ["s-class", "7 series", "a8", "range rover"].includes(car.model.toLowerCase())) {
        // TODO: Decide if Long should be in the title of the car or somewhere else.
        // return text + " Long";
        return text;
    }

    return text;
}

export function getModelLabel(car: CarItem) {
    let text = car.model.startsWith("i") ? car.model : capitalizeFirstLetter(car.model);
    text = text.replace(/_/gi, " ");
    text = tryGolfCarModel(car, text);
    text = tryMercedesBenzCarModel(car, text);
    text = tryLongSuffix(car, text);
    return text;
}

export function buildImageS3Url(car: CarItem, nr: number) {
    return `https://getcarscdkstack-getcarscdkpicturesofcarposts5098f9-p5xlugpcdiet.s3.eu-central-1.amazonaws.com/${car.postShortcode!}/${nr}${car?.postShortcode?.includes("/") ? ".jpeg" : ".jpg"}`;
}

interface SearchProps {
    includeIg: boolean;
    showPagination: boolean;
    showHero: boolean;
    pageSize: number;
}

const Home: React.FC<SearchProps> = ({includeIg, showPagination, showHero, pageSize}) => {
    const { adminToken } = useParams<{ adminToken: string }>();
    const dispatch: AppDispatch = useDispatch();

    // Access filters and page from the filter state, not car state
    const filters = useSelector((state: RootState) => state.filter.filters);
    const pageIgDisplay = useSelector((state: RootState) => state.filter.pageIgDisplay);
    const pageNormalDisplay = useSelector((state: RootState) => state.filter.pageNormalDisplay);

    const handleFilter = (newFilters: any) => {
        dispatch(setFilters(newFilters));  // Dispatch action to update filters
        dispatch(setPageNormalDisplay(1));  // Reset to page 1
        dispatch(setPageSize(pageSize));  // Reset to page 1
        sessionStorage.setItem('filters', JSON.stringify(newFilters));  // Persist filters in sessionStorage
    };

    const navigate = useNavigate();  // useNavigate hook to handle navigation

    const [hasMorePages, setHasMorePages] = useState(true); // Track if there are more pages

    const { data: igCars, error: igError, isLoading: igLoading } = useQuery<CarItem[], Error>(
        ['igCars', { sourceName: 'IG' }, pageIgDisplay, pageSize],
        () => fetchCars({ sourceName: 'IG' }, pageIgDisplay, pageSize),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                setHasMorePages(data.length === pageSize);
            },
        }
    );

    const { data: cars, error, isLoading } = useQuery<CarItem[], Error>(
        ['cars', filters, pageNormalDisplay, pageSize],
        () => fetchCars(filters, pageNormalDisplay, pageSize),
        {
            keepPreviousData: true,
            initialData: () => {
                // Check if we have cached data for this page
                return queryClient.getQueryData(['cars', filters, pageNormalDisplay, pageSize]);
            },
            onSuccess: (data) => {
                // setShowLatestPostCaptionValue(false);
                setHasMorePages(data.length === pageSize);
            }
        }
    );

    useEffect(() => {
        if (!isLoading) {

            const savedFilters = sessionStorage.getItem('filters');
            if (savedFilters) {
                setFilters(JSON.parse(savedFilters)); // Restore filters
            } else {
                queryClient.invalidateQueries("cars");  // Re-fetch the data when filters are cleared
            }

            const savedPosition = sessionStorage.getItem('scrollPosition');
            if (savedPosition) {
                window.scrollTo(0, parseInt(savedPosition, 10)); // Restore scroll position
                sessionStorage.removeItem('scrollPosition'); // Clean up after restoring
            }
        }
    }, [isLoading, filters]);  // Add `filters` as a dependency to refresh data on filter change

    const fetchCars = async (filters: any, page: number, pageSize: number) => {
        const savedPage = sessionStorage.getItem('currentPage');
        if (savedPage) {
            setPageNormalDisplay(Number(savedPage)); // Restore the page number
        }

        console.log("page is");
        console.log(page);
        const {
            brand,
            model,
            minYear,
            maxYear,
            minPrice,
            maxPrice,
            minKm,
            maxKm,
            fuelTypes,
            transmission,
            sourceName,
            keywords,
            sort,
        } = filters;

        // Build the query string with the filters
        const queryParams = new URLSearchParams();
        let documentTitle = "";

        if (brand) {
            queryParams.append('brand', brand);
            documentTitle += brand;
        }
        if (model) {
            queryParams.append('model', model);
            documentTitle += " " + model;
        }
        if (minYear) {
            queryParams.append('min_year', minYear);
            documentTitle += " " + minYear;
        }
        if (maxYear) {
            queryParams.append('max_year', maxYear);
            documentTitle += "-" + maxYear;
        }
        if (minPrice) {
            queryParams.append('min_price', minPrice);
        }
        if (maxPrice) {
            queryParams.append('max_price', maxPrice);
        }
        if (minKm) {
            queryParams.append('min_km', minKm);
        }
        if (maxKm) {
            queryParams.append('max_km', maxKm);
        }
        if (sourceName) {
            queryParams.append('sourceName', sourceName);
            documentTitle += " " + sourceName;
        }

        if (fuelTypes && fuelTypes.length > 0) {
            queryParams.append('fuel_type', fuelTypes.join(','));  // Send multiple fuel types as a comma-separated string
        }

        if (transmission && transmission !== 'any') {
            let value = transmission === "Manual" ? transmission : "Automatic";
            queryParams.append('transmission', value.toUpperCase()); // Only append if not "any"
            documentTitle += " " + value;
        }

        if (keywords) {
            queryParams.append('caption', keywords);
            documentTitle += " " + keywords;
        }

        // Handle sorting
        if (sort && sort !== undefined && sort !== null) {
            const [sortField, ascending] = sort.split('&');
            queryParams.append('sort', sortField);
            if (!ascending) {
                queryParams.append('ascending', 'true');
            }
        }

        if (pageSize) queryParams.append('pageSize', pageSize.toString());
        queryParams.append('page', page.toString());


        document.title = documentTitle && documentTitle.length > 0 ? documentTitle + " - AutoShqip" : "AutoShqip - shitje makinash";
        const queryString = queryParams.toString();
        const url = `${API_BASE_URL}/cars/search?${queryString}`;

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Error fetching cars');
        }
        return response.json();
    };

    let canEditContent = adminToken !== null && adminToken !== undefined && adminToken.length > 0;

    function handleCarPostClick(id: string) {
        sessionStorage.setItem('scrollPosition', window.scrollY.toString()); // Save scroll position
        sessionStorage.setItem('currentPage', pageIgDisplay.toString());  // Save the current page
        navigate(`/car-details/${id}`); // Navigate to the car details page
    }

    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ padding: 0, backgroundColor: backgroundColor}}>
                {/* Hero Section */}
                {showHero && <HeroSection />}

                {/* Filter Form comes directly after the Hero Section */}
                <Container sx={{ marginTop: '20px' }} >
                    <FilterForm onFilter={handleFilter} initialFilters={filters} moveToSearch={!canEditContent} />
                </Container>

                {includeIg && <CarPostList
                    cars={includeIg && igCars}
                    isLoading={igLoading}
                    error={igError}
                    includeIg={includeIg}
                    showPagination={showPagination}
                    page={pageIgDisplay}
                    setPageReducer={setPageIgDisplay}
                    hasMorePages={hasMorePages}
                    padding={padding}
                    handleCarClick={handleCarPostClick}
                    title="🔥 Postimet e fundit nga Instagrami 🔥"  // Optional: Pass a title for IG posts
                    canEditContent={canEditContent}
                    adminToken={adminToken}
                />}

                <CarPostList
                    cars={cars}
                    isLoading={isLoading}
                    error={error}
                    includeIg={includeIg}
                    showPagination={showPagination}
                    page={pageNormalDisplay}
                    setPageReducer={setPageNormalDisplay}
                    hasMorePages={hasMorePages}
                    padding={padding}
                    handleCarClick={handleCarPostClick}
                    title={"🔥 Të gjitha postimet e fundit 🔥"}
                    canEditContent={adminToken !== null && adminToken !== undefined && adminToken.length > 0}
                    adminToken={adminToken}
                />
            </Container>
        </ThemeProvider>
    );
};

export function getTransmissionLabel(transmission: string | null | undefined) {
    if (!transmission) {
        return "Automat";
    }

    return `${transmission === "MANUAL" ? "Manual" : "Automat"}`;
}

export function getFuelTypeLabel(fuelTypeId: number | null | undefined) {
    if (fuelTypeId === null || fuelTypeId === undefined) {
        return "";
    }

    switch (fuelTypeId) {
        case 1: return "Naftë";
        case 2: return "Benzinë";
        case 3: return "B+Gas";
        case 4: return "Gas";
        case 5: return "Hidrogjen";
        case 6: return "Elektrike";
        default: return "";
    }
}

export function capitalizeFirstLetter(text: string): string {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function formatNumberWithDots(num: number): string {
    if (!num)
        return "";
    return num.toLocaleString('de-DE');
}

// Wrap your app with QueryClientProvider
const App: React.FC<SearchProps> = ({includeIg, showPagination, showHero, pageSize}) => (
        <QueryClientProvider client={queryClient}>
            <Home includeIg={includeIg} showPagination={showPagination} showHero={showHero} pageSize={pageSize}/>
        </QueryClientProvider>
);

export default App;
