// src/store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import carReducer from './carSlice';  // Import car reducer
import carDetailsReducer from './carDetailsSlice';  // Import car reducer
import filterReducer from './filterSlice';

export const store = configureStore({
    reducer: {
        filter: filterReducer,  // Use the filter reducer
        car: carReducer,        // Use the car reducer
        carDetails: carDetailsReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
