// src/store/carDetailsSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const API_BASE_URL = `https://api.autoshqip.com`;

interface CarDetailsState {
    carDetails: any | null;
    loading: boolean;
    error: string | null;
}

const initialState: CarDetailsState = {
    carDetails: null,
    loading: false,
    error: null,
};

// Async thunk to fetch car details
export const fetchCarDetails = createAsyncThunk(
    'carDetails/fetchCarDetails',
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await fetch(`${API_BASE_URL}/cars/search/${id}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            return result[0];
        } catch (err: any) {
            return rejectWithValue(err.message);
        }
    }
);

export const carDetailsSlice = createSlice({
    name: 'carDetails',
    initialState,
    reducers: {
        resetCarDetails: (state) => {
            state.carDetails = null;
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCarDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCarDetails.fulfilled, (state, action) => {
                state.carDetails = action.payload;
                state.loading = false;
            })
            .addCase(fetchCarDetails.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            });
    },
});

export const { resetCarDetails } = carDetailsSlice.actions;
export default carDetailsSlice.reducer;
