import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './pages/About';
import CarDetails from "./pages/CarDetails";
import Home from "./pages/Home";
import {useDispatch} from "react-redux";
import {Container} from "@mui/material";
import {setFilters} from "./store/filterSlice";
import Contact from "./pages/Contact";
import Bookmarks from "./pages/bookmarks/Bookmarks";

const App: React.FC = () => {
    const [searchKey, setSearchKey] = useState(0);  // Add key state for Home re-render
    const dispatch = useDispatch();  // Add this line to access the Redux dispatcher

    const handleLogoClick = () => {
        sessionStorage.clear();  // Clear sessionStorage
        dispatch(setFilters({}));  // Reset Redux filters
        setSearchKey(prevKey => prevKey + 1);  // Change the key to force re-render
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', marginBottom: "0rem"}}>
            <Router>
                <Container sx={{ padding: 0 }}>
                {/* Pass handleLogoClick as a prop to Navbar */}
                <Navbar onLogoClick={handleLogoClick}/>
                <Routes>
                    <Route path="/"                     element={<Home includeIg={true} showHero={true} showPagination={true} pageSize={10}/>}/>
                    <Route path="/search"               element={<Home includeIg={false} showHero={false} showPagination={true} pageSize={30}/>}/>
                    <Route path="/change/:adminToken"   element={<Home includeIg={true} showHero={false} showPagination={true} pageSize={10}/>}/>
                    <Route path="/car-details/:id"      element={<CarDetails/>}/>
                    <Route path="/bookmarks"            element={<Bookmarks/>}/>
                    <Route path="/about"                element={<About/>}/>
                    <Route path="/contact"              element={<Contact/>}/>
                </Routes>
                <Footer/>
                </Container>
            </Router>
        </div>
    );
};

export default App;
