import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import {API_BASE_URL} from "../store/carDetailsSlice";

interface Props {
    postShortcode: string;
    adminToken: string | undefined;
    addAlert: (message: string, type: 'success' | 'error') => void;
}

const DeleteCarButton: React.FC<Props> = ({ postShortcode, adminToken, addAlert }) => {
    // State to track click count and timer
    const [clickCount, setClickCount] = useState(0);

    // Effect to reset the click count after a delay
    useEffect(() => {
        if (clickCount === 0) return;

        // Set a timer to reset click count after 2 seconds
        const timer = setTimeout(() => setClickCount(0), 2000);

        // Clear timer on cleanup or if the click count resets
        return () => clearTimeout(timer);
    }, [clickCount]);

    // Function to handle delete action
    const handleDeleteCar = async () => {
        try {
            const response = await axios.delete(
                `${API_BASE_URL}/cars/${encodeURIComponent(postShortcode)}`,
                {
                    headers: {
                        "Content-Type": 'application/json',
                        Authorization: adminToken,
                    },
                }
            );

            if (response.status !== 200) {
                throw new Error(`Failed to delete car with ID ${postShortcode}`);
            }

            addAlert('Car deleted successfully!', 'success');
        } catch (error: any) {
            addAlert(`${error.message}`, 'error');
        }
    };

    // Click handler for the delete button
    const handleClick = () => {
        setClickCount((prevCount) => prevCount + 1);

        // Trigger delete if clicked 3 times within the time limit
        if (clickCount + 1 === 3) {
            handleDeleteCar();
            setClickCount(0); // Reset the click count after deletion
        }
    };

    return (
        <Button
            variant="contained"
            sx={{
                backgroundColor: "darkred",
                padding: 0,
                margin: 0,
                width: '8%',
                height: '100%',
                lineHeight: 1,
                borderRadius: 0,
            }}
            onClick={handleClick}
        >
            🗑️
        </Button>
    );
};

export default DeleteCarButton;
