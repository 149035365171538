import React from 'react';
import {AppDispatch, RootState} from "../store/store";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/material";
import igLogo from "../assets/ig_logo.png";
import emailLogo from "../assets/email_logo.png";
import {mailTo} from "../pages/Contact";
import {isMobileDevice} from "../pages/CarDetails";
import {useNavigate} from "react-router-dom";

const instagramUsername = `autoshqip.official`;

const Footer: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const { carDetails, loading, error } = useSelector((state: RootState) => state.carDetails);

    return (
        <>
            {!loading && (
              <footer style={{
                  marginTop: "auto", // Pushes the footer to the bottom of the page content
                  marginBottom: "0rem",
                  paddingBottom: "0rem",
                  minHeight: "10rem",
                  fontFamily: "Heebo, Inter, sans-serif",
                  backgroundColor: "#131313",
                  color: "whitesmoke",
                  textAlign: 'center',
                  fontSize: "1.1rem",
                  padding: "1rem"
              }}>
                  <p style={{fontWeight: "bold"}}>
                      &copy; 2024 AutoShqip
                  </p>
                  <p>
                      Të gjitha të drejtat e rezervuara.
                  </p>
                  <p>
                      Jemi duke shtuar opsione të reja,
                      <br/>
                      update se shpejti.
                  </p>

                  <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (isMobileDevice()) {
                            window.location.href = `instagram://user?username=${instagramUsername}`;
                        } else {
                            window.open(`https://instagram.com/${instagramUsername}`, '_blank');
                        }
                    }}
                  >
                      <Box
                        component="img"
                        sx={{
                            height: 50,
                            mr: 2,
                            cursor: 'pointer',
                        }}
                        alt="IG logo"
                        src={igLogo}
                      />
                  </a>


                  <a href={mailTo}>
                      <Box
                        component="img"
                        sx={{
                            height: 50,
                            mr: 2,
                            cursor: 'pointer',
                        }}
                        alt="Email logo"
                        src={emailLogo}
                      />
                  </a>

                  <p style={{fontSize: '0.88rem', fontFamily: "Heebo, sans-serif", color: "white"}}>
                      <a
                        href="#"
                        style={{color: "whitesmoke"}}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate("/about");
                            window.scrollTo(0, 0);
                        }}>
                          Rreth nesh
                      </a>

                      <a
                        href="#"
                        style={{marginLeft: "1rem", color: "whitesmoke"}}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate("/contact");
                            window.scrollTo(0, 0);
                        }}>
                          Kontakto
                      </a>

                      <a style={{marginLeft: "1rem", color: "whitesmoke"}}
                         href="https://docs.google.com/document/d/179lJRrgnWlHxVh5fovxPAhpWJamMK6fGfvidBEVqjtw">
                          Politika e privatësisë
                      </a>
                  </p>
              </footer>
            )}
        </>
    );
};

export default Footer;
