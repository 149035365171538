import React, { ReactNode } from 'react';

interface WrapperProps {
    children: ReactNode; // Accepts any React elements or text as children
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
    return (
        <div style={{ border: '2px solid #ccc', padding: '16px', borderRadius: '8px' }}>
            {children}
        </div>
    );
};

export default Wrapper;
