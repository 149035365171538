import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import modelsData from '../assets/models.json'; // Import car models from models.json

// Define CarState
interface CarState {
    carBrands: string[];
    carModels: Record<string, string[]>;  // Define carModels as a Record (key-value pair)
    carDetails: any | null;
    loading: boolean;
    error: string | null;
}

// Convert the models data into a Map (optional)
const carModels: Record<string, string[]> = modelsData;

// Initial state for car slice
const initialState: CarState = {
    carBrands: ["🇩🇪 Gjermane", "Audi", "BMW", "Mercedes-Benz", "Porsche", "Volkswagen", "Opel", "Maybach", "Smart",
        "------------------------", "🇯🇵 Japoneze", "Toyota", "Lexus", "Honda", "Nissan", "Mazda", "Mitsubishi", "Acura", "Infiniti", "Subaru", "Suzuki", "Daihatsu",
        "-----------------------", "🇺🇸 Amerikane", "Ford", "Cadillac", "Chevrolet","Chrysler", "Jeep", "Tesla", "Rivian","Dodge", "GMC", "RAM", "Hummer", "Lincoln",
        "----------------------", "🇰🇷 Koreane", "Hyundai", "Kia", "Genesis", "Daewoo", "SsangYong",
        "---------------------", "🇬🇧 Angleze", "Land Rover", "Jaguar", "Mini", "Aston Martin", "Bentley", "Rolls-Royce", "McLaren",
        "--------------------", "🇸🇪 Suedeze", "Volvo", "Saab", "Polestar",
        "-------------------", "🇮🇹 Italiane", "Fiat", "Alfa Romeo", "Ferrari", "Lamborghini", "Pagani", "Maserati", "Lancia",
        "------------------", "🇫🇷 Franceze", "Peugeot", "Renault", "Citroen",
        "-----------------", "🇨🇳 Kineze", "BYD", "MG", "Geely", "XPENG", "NIO", "Chery", "Xiaomi", "ShuangHuan",
        "----------------", "👉 Të tjera", "Seat", "Cupra", "Dacia", "Skoda", "Vauxhall", "Tata", "Lada", "Bugatti", "Lucid"],
    carModels: carModels,  // Initialize car models from the models.json data
    carDetails: null,  // Add carDetails to state
    loading: false,  // Add loading state
    error: null,  // Add error state
};

// Create slice
export const carSlice = createSlice({
    name: 'car',
    initialState,
    reducers: {
        setCarDetails: (state, action: PayloadAction<any>) => {
            state.carDetails = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

// Export actions
export const { setCarDetails, setLoading, setError } = carSlice.actions;

export default carSlice.reducer;
