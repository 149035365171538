// src/components/Navbar.tsx

import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {Link, useNavigate} from 'react-router-dom';
import logo from '../assets/AutoShqip logo prototype.png'; // Import the logo image

interface NavbarProps {
    onLogoClick: () => void;  // Declare the onLogoClick prop
}

const Navbar: React.FC<NavbarProps> = ({ onLogoClick }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();  // useNavigate hook

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogoClick = () => {
        sessionStorage.clear();  // Clear session storage (filters and other data)
        navigate('/');  // Navigate to the home page without reloading the app
    };

    const menuItems = [
        { label: 'Kryefaqe', path: '/' },
        { label: 'Kërko', path: '/search' },
        { label: 'Të ruajtura', path: '/bookmarks' },
        { label: 'Rreth nesh', path: '/about' },
        { label: 'Kontakto', path: '/contact' }
    ];

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white' }}>
            <Toolbar sx={{paddingLeft: '5px', paddingRight: '5px'}}>
                <Link to="/" onClick={onLogoClick}>  {/* Call onLogoClick on logo click */}
                    <Box
                        component="img"
                        sx={{
                            height: 50,
                            mr: 2,
                            cursor: 'pointer',  // Add cursor to indicate clickability
                        }}
                        alt="Logo"
                        src={logo}  // Use the imported logo image
                    />
                </Link>

                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {/*AutoShqip*/}
                </Typography>

                {/* Mobile Menu */}
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <IconButton
                        size="large"
                        edge="start"
                        sx={{ color: 'black' }}
                        aria-label="menu"
                        onClick={handleMenuOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {menuItems.map((item) => (
                            <MenuItem key={item.label} onClick={handleMenuClose} component={Link} to={item.path}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

                {/* Desktop Menu */}
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    {menuItems.map((item) => (
                        <Button
                            key={item.label}
                            component={Link}
                            to={item.path}
                            sx={{ textTransform: 'none' , color: 'black', fontFamily: "Inter, sans-serif"}}
                        >
                            {item.label}
                        </Button>
                    ))}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
