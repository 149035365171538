import React, { useState, useEffect } from 'react';
import './Alert.css'; // Create a CSS file for styling

interface AlertProps {
    message: string;
    type: 'success' | 'error';
    onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ message, type, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(onClose, 5000);
        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className={`alert ${type}`}>
            <span>{message}</span>
            <button className="close-btn" onClick={onClose}>×</button>
        </div>
    );
};

export default Alert;
