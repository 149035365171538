import React, {useEffect, useState} from 'react';
import {IconButton, MenuItem, Paper, TextField} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {isBrandDisabled} from "./CarPostList";

interface SearchableDropdownProps {
    label: string;
    options: string[];
    selectedValue: string;
    onSelect: (value: string) => void;
    onClear: () => void;
    disabled: boolean;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
                                                                   label,
                                                                   options,
                                                                   selectedValue,
                                                                   onSelect,
                                                                   onClear,
                                                                   disabled,
                                                               }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState<string[]>(options);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        setFilteredOptions(
            options
                .filter((option) =>
                    searchTerm === '' || (!isBrandDisabled(option) && option.toLowerCase().includes(searchTerm.toLowerCase()))
                )
                .sort((a, b) => {
                    const lowerSearchTerm = searchTerm.toLowerCase();
                    const aStartsWith = a.toLowerCase().startsWith(lowerSearchTerm);
                    const bStartsWith = b.toLowerCase().startsWith(lowerSearchTerm);

                    if (aStartsWith && !bStartsWith) return -1;
                    if (!aStartsWith && bStartsWith) return 1;
                    return 0;
                })
        );
    }, [searchTerm, options]);


    useEffect(() => {
        setSearchTerm(selectedValue); // Sync searchTerm with selectedValue when selectedValue changes
    }, [selectedValue]);


    const handleFocus = () => setDropdownOpen(true);
    const handleBlur = () => setTimeout(() => setDropdownOpen(false), 150);

    return (
        <div style={{ position: 'relative' }}>
            <TextField
                fullWidth
                label={label}
                disabled={disabled}
                value={searchTerm}
                onInput={(e) => {
                    setSearchTerm((e.target as HTMLInputElement).value);
                    setDropdownOpen(true);
                }}
                onFocus={handleFocus}
                onClick={handleFocus}
                onBlur={handleBlur}
                size="small"
                InputProps={{
                    endAdornment: selectedValue && (
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                setSearchTerm(''); // Clear search term on click
                                onClear(); // Call onClear to reset parent component's state
                            }}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    backgroundColor: 'white',
                    fontFamily: "Inter, sans-serif"
                }}
            />

            {dropdownOpen && filteredOptions.length > 0 && (
                <Paper
                    style={{
                        position: 'absolute',
                        width: '100%',
                        maxHeight: '20rem',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        marginTop: 4,
                        zIndex: 10,
                        touchAction: 'pan-y'
                    }}
                >
                    {filteredOptions.map((option) => (
                        <MenuItem
                            key={option}
                            disabled={isBrandDisabled(option)}
                            onClick={() => {
                                onSelect(option);
                                setSearchTerm(option);
                                setDropdownOpen(false);
                            }}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Paper>
            )}
        </div>
    );
};

export default SearchableDropdown;
