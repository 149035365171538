// BookmarkIcon.tsx
import React, {useEffect, useState} from 'react';
import bookmarkIcon from '../assets/bookmark_icon.png';
import bookmarkIconFilled from '../assets/bookmark_icon_filled.png';
import {isBookmarked, toggleBookmark} from "../pages/bookmarks/bookmarkUtil";

interface BookmarkIconProps {
    carId: string;
}

const BookmarkIcon: React.FC<BookmarkIconProps> = ({carId}) => {
    const [isBookmarkedState, setIsBookmarkedState] = useState<boolean>(false);

    useEffect(() => {
        setIsBookmarkedState(isBookmarked(carId));
    }, [carId]);

    const handleToggleBookmark = () => {
        toggleBookmark(carId);
        setIsBookmarkedState(!isBookmarkedState);
    };

    return (
        <img
            src={isBookmarkedState ? bookmarkIconFilled : bookmarkIcon}
            alt="Bookmark icon"
            onClick={handleToggleBookmark}
            style={{
                width: '1.3rem',
                height: '1.7rem',
                filter: isBookmarkedState ? 'brightness(40%)' : 'none',
                cursor: 'pointer',
                position: 'relative',
                top: '0.2rem',
                right: '-0.5rem',
                zIndex: 1
            }}
        />
    );
};

export default BookmarkIcon;
