// utils/bookmarkUtils.ts
export const BOOKMARKS_KEY = 'bookmarks';
export const MAX_CARS_TO_BOOKMARK = 50;

export const loadBookmarks = (): string[] => {
    return JSON.parse(localStorage.getItem(BOOKMARKS_KEY) || '[]');
};

export const saveBookmarks = (bookmarks: string[]): void => {
    localStorage.setItem(BOOKMARKS_KEY, JSON.stringify(bookmarks));
};

export const isBookmarked = (carId: string): boolean => {
    const bookmarks = loadBookmarks();
    return bookmarks.includes(carId);
};

export const toggleBookmark = (carId: string): string[] => {
    const bookmarks = loadBookmarks();
    let updatedBookmarks: string[];

    if (bookmarks.includes(carId)) {
        // Remove bookmark
        updatedBookmarks = bookmarks.filter(id => id !== carId);
    } else if (bookmarks.length >= MAX_CARS_TO_BOOKMARK) {
        updatedBookmarks = [...bookmarks.slice(1), carId];
    } else {
        updatedBookmarks = [...bookmarks, carId];
    }

    saveBookmarks(updatedBookmarks);
    return updatedBookmarks;
};
