import React, {useEffect, useState} from 'react';
import Grid2 from '@mui/material/Grid2';
import CarPostListItem from "../../components/CarPostListItem";
import {TypographyStyled} from "../../components/CarPostList";
import axios from "axios";
import {loadBookmarks} from "./bookmarkUtil";
import {Loading} from "../../components/Loading";
import {padding} from "../Home";

const PAGE_TITLE = "Të ruajtura";

const Bookmarks: React.FC = () => {
    document.title = PAGE_TITLE + " - AutoShqip"
    const [bookmarkedCars, setBookmarkedCars] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true); // Add loading state

    useEffect(() => {
        const fetchBookmarkedCars = async () => {
            setLoading(true);  // Set loading to true at the start of data fetching
            const carIds = loadBookmarks();

            if (carIds.length === 0) {
                setBookmarkedCars([]);
                setLoading(false);  // Stop loading if no bookmarks
                return;
            }

            try {
                const response = await axios({
                    method: 'post',
                    url: 'https://api.autoshqip.com/cars/get-cars-by-ids',
                    data: carIds
                });

                setBookmarkedCars(response.data);
            } catch (error) {
                console.error("Error fetching bookmarked cars:", error);
            } finally {
                setLoading(false);  // Set loading to false once data is fetched or an error occurs
            }
        };

        fetchBookmarkedCars();
    }, []);

    if (loading) {
        return <Loading/>; // Show loading spinner while fetching data
    }

    if (bookmarkedCars && bookmarkedCars.length === 0) {
        return (
            <>
                <TypographyStyled variant="h6" sx={{m: "0.7rem", mb: "3rem"}} gutterBottom>
                    Asnjë makinë e ruajtur
                </TypographyStyled>
            </>);
    }

    return (
        <>
            <TypographyStyled variant="h5" sx={{m: "0.7rem"}} gutterBottom>
                {PAGE_TITLE}
            </TypographyStyled>
            <Grid2 container spacing={padding} rowSpacing={padding} mb={2}>
                {bookmarkedCars.map((car) => (
                    <Grid2 key={car.id} size={100}>
                        <CarPostListItem car={car}/>
                    </Grid2>
                ))}
            </Grid2>
        </>
    );
};

export default Bookmarks;
