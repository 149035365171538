// CarPostListItem.tsx
import React from 'react';
import {Box, Grid2} from '@mui/material';
import {
    buildImageS3Url,
    capitalizeFirstLetter,
    CarItem,
    formatNumberWithDots,
    getFuelTypeLabel,
    getModelLabel,
    getPriceLabel,
    getTransmissionLabel,
    TRIM_LETTERS_TO_SLICE
} from '../pages/Home';
import clockImage from '../assets/clock.png';
import {getDateAndTimeAgo, isMobileDevice} from "../pages/CarDetails";
import ig_logo_plain from "../assets/ig_logo_plain.png";
import {Image, ImageContainer, StyledCard, TextContent, TRIM_TEXT_TO_REPLACE, TypographyStyled} from "./CarPostList";
import {useNavigate} from "react-router-dom";

interface CarPostListItemProps {
    car: CarItem;
}

const CarPostListItem: React.FC<CarPostListItemProps> = ({car}) => {
    const navigate = useNavigate();
    const isWebsitePost = car?.postShortcode?.includes("/");
    const carModel = getModelLabel(car);
    const carBrand = capitalizeFirstLetter(car.brand);
    const priceLabel = getPriceLabel(car);
    let kmValue = formatNumberWithDots(Math.round(car.km));
    if (kmValue === "")
        kmValue = "--";

    let isMobile = isMobileDevice();

    let carTrimForDisplay = car.trim || "";
    for (const text of TRIM_TEXT_TO_REPLACE) {
        carTrimForDisplay = carTrimForDisplay.replace(text, "");
    }

    function handleCarPostClick(id: string) {
        sessionStorage.setItem('scrollPosition', window.scrollY.toString());
        navigate(`/car-details/${id}`);
    }

    return (
        <Grid2 key={car.id} sx={{width: '100%'}} onClick={() => handleCarPostClick(car.id)}>
            <StyledCard sx={{position: 'relative'}}>
                <ImageContainer sx={{
                    width: isMobile ? '50%' : '24%',
                    position: 'relative' // Make the parent a positioning context
                }}>
                    <Image
                        src={buildImageS3Url(car, isWebsitePost ? 2 : 1)}
                        alt={`${carBrand} ${carModel}`}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover', // Ensures image is zoomed and covers the container
                            position: 'absolute', // Stack on top of each other
                            top: 0,
                            left: 0
                        }}
                    />
                    {car.isSold && (
                        <div style={{
                            display: 'flex',
                            position: 'absolute',
                            alignItems: 'center',
                            justifyContent: 'center',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '15%',
                            backgroundColor: '#b20000',
                            color: 'white',
                            textAlign: 'center',
                            fontWeight: 'bold'
                        }}>
                            S H I T U R
                        </div>
                    )}
                </ImageContainer>

                {!isWebsitePost && (
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (isMobile && car.mediaId) {
                                window.location.href = `instagram://media?id=${car.mediaId}`;
                            } else {
                                window.open(`https://instagram.com/p/${car.postShortcode}`, '_blank');
                            }
                        }}
                        style={{
                            position: 'absolute',
                            bottom: '0.2rem',
                            right: '0.4rem',
                            zIndex: 1
                        }}
                    >
                        <img src={ig_logo_plain} alt="IG Logo"
                             style={{width: '2rem', height: '2rem', filter: 'grayscale(0%)'}}>
                        </img>
                    </a>
                )}


                <TextContent>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',  // Keep stats at the bottom
                            pt: '0'  // Optional: Adjust padding if needed
                        }}
                    >
                        <>
                            <TypographyStyled variant="body1"
                                              sx={{fontSize: isMobile ? '0.93rem' : '1.1rem'}}>
                                <strong>{carBrand} {carModel}</strong>
                            </TypographyStyled>
                            <TypographyStyled variant="body1" sx={{
                                fontSize: isMobile ? '0.85rem' : '1rem',
                                paddingTop: '0.25rem'
                            }}>
                                {isMobile && carTrimForDisplay.length > TRIM_LETTERS_TO_SLICE ? carTrimForDisplay.slice(0, TRIM_LETTERS_TO_SLICE) + "..." : carTrimForDisplay}
                            </TypographyStyled>
                        </>
                    </Box>
                    <TypographyStyled variant="body2"
                                      sx={{
                                          color: 'darkgreen',
                                          fontSize: isMobile ? '1.15rem' : '1.3rem',
                                          paddingTop: '0.65rem'
                                      }}>
                        <strong>{priceLabel}</strong>
                    </TypographyStyled>

                    <TypographyStyled variant="body1" sx={{
                        fontSize: isMobile ? '0.9rem' : '1rem',
                        alignItems: "center",
                        paddingTop: '0.65rem'
                    }}>
                        <img src={clockImage} alt="IG Logo"
                             style={{width: '0.8rem', height: '0.8rem'}}/>
                        <span> {capitalizeFirstLetter(getDateAndTimeAgo(car.postedTime).timeAgo)}</span>
                    </TypographyStyled>

                    <Box
                        sx={{
                            position: 'absolute', // Position at the bottom-left of TextContent
                            bottom: 0,
                            left: 0,
                            width: isMobile ? '85%' : '30%', // Adjust width as needed
                            display: 'grid',
                            gridTemplateColumns: '45fr 55fr',
                            gridTemplateRows: '45fr 55fr',
                            gap: '0px',
                            border: '0px solid lightgrey', // Vertical separator
                            ml: '0.3rem',
                            mb: '0.3rem',
                            padding: '0'
                        }}
                    >
                        {/* Top-left: Year */}
                        <TypographyStyled variant="body2" sx={{
                            textAlign: 'left',
                            p: 0,
                            fontSize: isMobile ? 'clamp(0.95rem, 1vw, 1rem)' : 'clamp(1.1rem, 1vw, 1rem)',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}>
                            {car.year}
                        </TypographyStyled>

                        {/* Top-right: KM */}
                        <TypographyStyled variant="body2" sx={{
                            textAlign: 'left',
                            p: 0,
                            fontSize: isMobile ? 'clamp(0.95rem, 1vw, 1rem)' : 'clamp(1.1rem, 1vw, 1rem)',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}>
                            {kmValue} KM
                        </TypographyStyled>

                        {/* Bottom-left: Transmission */}
                        <TypographyStyled variant="body2" sx={{
                            textAlign: 'left',
                            p: 0.15,
                            fontSize: isMobile ? 'clamp(0.95rem, 1vw, 1rem)' : 'clamp(1.1rem, 1vw, 1rem)',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}>
                            {getTransmissionLabel(car.transmission).trim()}
                        </TypographyStyled>

                        {/* Bottom-right: Fuel Type */}
                        <TypographyStyled variant="body2" sx={{
                            textAlign: 'left',
                            p: 0,
                            fontSize: isMobile ? 'clamp(0.95rem, 1vw, 1rem)' : 'clamp(1.1rem, 1vw, 1rem)',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}>
                            {getFuelTypeLabel(car.fuelTypeId)}
                        </TypographyStyled>

                    </Box>
                </TextContent>
            </StyledCard>
        </Grid2>
    );
};

export default CarPostListItem;
