// src/store/filterSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define FilterState
interface FilterState {
    filters: any;
    pageIgDisplay: number;
    pageNormalDisplay: number;
    pageSize: number;
}

// Initial state for filter
const initialState: FilterState = {
    filters: {},  // Default filters
    pageIgDisplay: 1,
    pageNormalDisplay: 1,
    pageSize: 30
};

// Create the filter slice
export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilters: (state, action: PayloadAction<any>) => {
            state.filters = action.payload;
        },
        setPageIgDisplay: (state, action: PayloadAction<number>) => {
            state.pageIgDisplay = action.payload;
        },
        setPageNormalDisplay: (state, action: PayloadAction<number>) => {
            state.pageNormalDisplay = action.payload;
        },
        setPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
    },
});

// Export actions
export const { setFilters, setPageIgDisplay, setPageNormalDisplay, setPageSize } = filterSlice.actions;

export default filterSlice.reducer;
